
import { ref, onMounted, computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import ApiService from '@/core/services/ApiService';
import { convertCamelToTitle } from '@/core/data/order';
import moment from 'moment';
import { Actions } from '@/store/enums/StoreEnums';

export default defineComponent({
  name: 'OrderWildcard',
  setup() {
    const route = useRoute();
    const store = useStore();
    const { id } = route.params;
    const { status } = route.query;
    const currentImage = ref('');
    const details = ref<any>([]);
    const order = ref<any>({});
    const customer = ref<any>({});
    const node = ref<any>({});
    const car = ref<any>({});
    const partner = ref<any>({});

    document.title = id.toString().slice(16).toUpperCase();

    const changeImage = (image) => {
      currentImage.value = image;
    };

    onMounted(async () => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading');

      const orderData = await ApiService.get(`order/${id}`);
      console.log(orderData.data.data);
      const customerData = await ApiService.get(
        `customer/${orderData.data.data.customer.id}`
      );
      const nodeData = await ApiService.get(
        `location/service-point/${orderData.data.data.servicePoint.id}`
      );
      const carData = await ApiService.get(`car/${orderData.data.data.car.id}`);
      const partnerData = await ApiService.get(
        `partner/${orderData.data.data.partner.id}`
      );

      order.value = orderData.data.data;
      customer.value = customerData.data.data;
      node.value = nodeData.data.data;
      car.value = carData.data.data;
      partner.value = partnerData.data.data;

      details.value = [
        {
          name: 'Customer Details',
          image: customer.value.profileImage,
          labelName: customer.value.name || '-',
          descriptions: [
            {
              icon: 'call',
              label: customer.value.phone,
            },
            {
              icon: 'email',
              label: customer.value.email,
            },
          ],
        },
        {
          name: 'Product Details',
          image: car.value.images?.[0]?.image,
          labelName: car.value.plate,
        },
        {
          name: 'Store Details',
          labelName: partner.value.organization?.name || '-',
          image: partner.value.profileImage,
          descriptions: [
            {
              icon: 'call',
              label: partner.value.organization?.phone,
            },
            {
              icon: 'email',
              label: partner.value.organization?.email,
            },
            {
              icon: 'location_on',
              label: `${partner.value.organization?.address} ${partner.value.organization?.subDistrict?.name} ${partner.value.organization?.district?.name} ${partner.value.organization?.province?.name} ${partner.value.organization?.postNumber}`,
            },
          ],
        },
      ];

      setCurrentPageBreadcrumbs(
        'Order',
        [
          {
            name: `Order ${status}` || 'Order Overview',
            path:
              `/order/${status
                ?.toString()
                .replace(/\s+/g, '-')
                .toLowerCase()}` || '/order/overview',
          },
          'Order Details',
        ],
        {}
      );

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading');
    });

    const day = computed(() => {
      if (order.value.days < 2) {
        return 'Day';
      } else {
        return 'Days';
      }
    });

    const carDescription = computed(() => {
      return `${car.value.type?.name}, ${car.value.brand?.name} ${car.value.model?.name} ${car.value.series?.name}, Year ${car.value.year}, ${car.value.plate}`;
    });

    return {
      changeImage,
      details,
      currentImage,
      order,
      customer,
      convertCamelToTitle,
      moment,
      day,
      node,
      car,
      carDescription,
      partner,
      id,
    };
  },
});
